import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useForm } from "../../../hooks/useForm";
import { ContactUsInput } from "../../../interfaces/others";
import { emptyValidator } from "../../../utils/validators/emptyValidator";
import publicService from "../../../services/PublicService";
import useApi from "../../../hooks/useApi";
import { toast } from "react-toastify";

const submit = (data: ContactUsInput) => publicService.submit_contact_form(data);

export const ContactUs: React.FC = () => {
    const contactUsApiRequest = useApi<{status: boolean; message: string}, ContactUsInput>(submit);

    const contactUsForm = useForm<ContactUsInput>(
        {
            email: "",
            message: "",
            name: ""
        },
        {
            email: emptyValidator,
            message: emptyValidator,
            name: emptyValidator
        }
    );

    const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
        contactUsForm.onChange(e.target.name as keyof ContactUsInput, e.target.value);
    }

    useEffect(() => {
        if (contactUsApiRequest.error) {
            toast(contactUsApiRequest.error, { type: "error", theme: "colored" });

            setTimeout(() => {
                contactUsApiRequest.reset();
            }, 3000);
        }
    }, [contactUsApiRequest.error])

    const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        if (contactUsApiRequest.loading) {
            return;
        }

        contactUsForm.resetFormErrors();

        const valid = contactUsForm.validate();

        if (valid) {
            const res = await contactUsApiRequest.request(contactUsForm.form);

            if (res && res.status) {
                toast(res.message, { type: "success", theme: "colored" });
                contactUsForm.reset();
            }
        }
    }

    return (
        <div className="bv-contact-us-page page">
            <div className="breadcrumb-area">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="breadcrumb-title text-center">
                                <h2>Contact</h2>
                                <ul className="breadcrumb-list">
                                    <li>Home</li>
                                    <li> <FontAwesomeIcon icon={icon({ name: 'angle-double-right', style: 'solid' })} /> </li>
                                    <li>Contacts</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="contact-meta-info-area mb-50">
                                <div className="contact-meta-info text-center">
                                    <div className="c-meta-icon">
                                        <FontAwesomeIcon icon={icon({ name: 'mobile', style: 'solid'})} />
                                    </div>
                                    <h4>Phone number</h4>
                                    <span>+1 (302) 440-4324 </span>
                                </div>

                                <div className="contact-meta-info text-center">
                                    <div className="c-meta-icon">
                                        <FontAwesomeIcon icon={icon({ name: 'envelope-circle-check', style: 'solid' })} />
                                    </div>
                                    <h4>Email</h4>
                                    <span>support@summittrades.live</span>
                                </div>                                
                            </div>


                        </div>

                        <div className="col-lg-8">
                            <div className="contact-area mb-50">
                                <h4 className="mb-50 text-center">Get in Touch</h4>
                                <form className="nft-contact-from" onSubmit={handleSubmit}>
                                    <div className="row g-4">
                                        <div className="col-12 col-lg-6">
                                            <input 
                                                className="form-control" 
                                                type="text" 
                                                name="name" 
                                                placeholder="Your Name" 
                                                required 
                                                value={contactUsForm.form.name}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-12 col-lg-6">
                                            <input 
                                                className="form-control" 
                                                type="email" 
                                                name="email"
                                                placeholder="Your Email" 
                                                required
                                                value={contactUsForm.form.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        
                                        <div className="col-12">
                                            <textarea 
                                                className="form-control" 
                                                name="message" 
                                                placeholder="Type your message..." 
                                                required 
                                                value={contactUsForm.form.message}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="col-12 text-center mt-30">
                                            <button disabled={contactUsApiRequest.loading} className="btn btn btn-box" type="submit">Send</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="map-area section-padding-100">
                <div className="container">
                    <div className="row">
                        
                    </div>
                </div>
            </div>
        </div>
    )
}